import React from "react";
import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import Social from "./Social";
import StickyNavigation from "./StickyNavigation";

const NavBar = ({ navigation, socialLinks, title, logo }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <StickyNavigation mobileMenuOpen={mobileMenuOpen}>
      <div className="bg-white bg-opacity-90">
        <nav
          className="max-w-[1400px] mx-auto px-6 py-4 md:py-6 md:px-8 flex items-center justify-between"
          aria-label="Global"
        >
          <div className="flex md:min-w-0 md:flex-1" aria-label="Global">
            <h1 className="m-0 p-0 text-[0px]">
              <AniLink paintDrip hex="#111" to="/">
                <span className="sr-only">{title}</span>
                <div
                  className="[&>svg]:fill-orange mt-0.5 [&>svg]:h-5 [&>svg]:md:h-6"
                  dangerouslySetInnerHTML={{ __html: logo }}
                />
              </AniLink>
            </h1>
            <div className="hidden lg:flex -mt-1.5 ml-8">
              <Social links={socialLinks} />
            </div>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-black hover:text-orange transition-all"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Abrir menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex md:min-w-0 md:gap-x-6">
            {navigation?.map((item) => (
              <AniLink
                paintDrip
                hex="#111"
                key={item.title}
                to={"/" + item.slug}
                className="font-semibold text-xl text-black hover:underline transition-all font-cursive uppercase [&.active]:text-orange [&.active]:underline"
                activeClassName="active"
              >
                {item.title}
              </AniLink>
            ))}
          </div>
        </nav>
        <Dialog
          as="div"
          className="bg-orange"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <Dialog.Panel
            focus="true"
            className="fixed inset-0 z-10 overflow-y-auto bg-white px-6 lg:hidden max-h-screen"
          >
            <div className="flex h-9 items-center justify-between fixed right-6 top-3">
              <div className="flex" />
              <div className="flex">
                <button
                  type="button"
                  className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-black hover:text-orange transition-all"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Fechar menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="flex justify-center flex-col min-h-screen">
              <div className="py-10 flex justify-between min-h-screen flex-col">
                <div className="flex">
                  <AniLink
                    paintDrip
                    hex="#111"
                    to="/"
                    className="mx-auto mt-5 mb-6"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">{title}</span>
                    <div
                      className="[&>svg]:fill-orange [&>svg]:h-8 [&>svg]:md:h-8"
                      dangerouslySetInnerHTML={{ __html: logo }}
                    />
                  </AniLink>
                </div>
                <div className="space-y-2">
                  {navigation?.map((item) => (
                    <AniLink
                      paintDrip
                      hex="#111"
                      key={item.title}
                      to={"/" + item.slug}
                      onClick={() => setMobileMenuOpen(false)}
                      className="mx-auto w-full text-center block py-2 px-3 font-semibold text-3xl text-black hover:underline transition-all font-cursive uppercase [&.active]:text-orange [&.active]:underline"
                      activeClassName="active"
                    >
                      {item.title}
                    </AniLink>
                  ))}
                </div>
                <div>
                  <Social links={socialLinks} />
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </div>
    </StickyNavigation>
  );
};

export default NavBar;
